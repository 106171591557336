import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {withSnackbar} from "notistack";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import {API_URL} from "../../config";
import List from "@material-ui/core/List";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import ModuleList from "../../components/Course/ModuleList/ModuleList";
import Typography from "@material-ui/core/Typography";
import ReactPlayer from "react-player/youtube";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        display: 'flex'
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 16
    },
    loading: {
        width: '100%',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
    },
    title: {
        flex: '1 1 100%',
    },
    lessonWrapper: {
        padding: theme.spacing(2),
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    action: {
        marginRight: 8
    },
    progressBar: {
        width: '100%'
    },
    sideBar: {
        width: 400,
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        overflowY: 'scroll'
    },
    appBarSpacer: theme.mixins.toolbar,
    wrapper: {
        position: 'relative',
        paddingTop: '56.25%'
    },
    player: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
    }
}));

const Home = ({ history, enqueueSnackbar }) => {

    const classes = useStyles();

    const [modules, setModules] = useState([]);
    const [userLessons, setUserLessons] = useState([]);
    const [userExams, setUserExams] = useState([]);

    const [currentLesson, setCurrentLesson] = useState(null);
    const [currentExam, setCurrentExam] = useState(null);

    const [openedModule, setOpenedModule] = useState(true);
    const [selectedItem, setSelectedItem] = useState({id: null, type: null});

    const [isItemLoading, setIsItemLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isSyncing, setIsSyncing] = useState(false);

    useEffect(() => {

        // Función que carga los módulos
        const loadModules = async () => {

            // Mostrar indicador de carga
            setIsLoading(true);

            try {

                // Hacer request
                const response = await axios.get(`${API_URL}/course`);

                // Actualizar state
                setModules(response.data.modules);
                setUserLessons(response.data.user_lessons);
                setUserExams(response.data.user_exams);

                if(response.data.user_lessons.length > 0){
                    setSelectedItem({
                        id: response.data.user_lessons[response.data.user_lessons.length - 1].id,
                        type: 'lesson'
                    });
                    await loadLesson(response.data.user_lessons[response.data.user_lessons.length - 1].id);
                } else {
                    setSelectedItem({
                        id: response.data.modules[0].lessons[0].id,
                        type: 'lesson'
                    });
                    await loadLesson(response.data.modules[0].lessons[0].id);
                }

            } catch(error) {

                enqueueSnackbar('Something went wrong loading the course.');

            }

            // Esconder indicador de carga
            setIsLoading(false);
        };

        // Cargar módulos
        loadModules();

    }, []);

    const loadLesson = async (id) => {

        setIsItemLoading(true);

        try {

            // Hacer request
            const response = await axios.get(
                `${API_URL}/lessons/${id}`
            );

            setCurrentLesson(response.data);
        } catch (error) {
            enqueueSnackbar('Something went wrong loading the lesson.');
        }

        setIsItemLoading(false);
    };

    const loadExam = async (id) => {

        setIsItemLoading(true);

        try {

            // Hacer request
            const response = await axios.get(
                `${API_URL}/exams/${id}`
            );

            const examData = response.data.exam;

            let examModule = modules.find(module => module.id === examData.module_id);

            let moduleLessonsCount = examModule.lessons.length;
            let userModuleLessonsCount = userLessons.filter(lesson => lesson.module_id === examModule.id).length;

            examData.available = moduleLessonsCount === userModuleLessonsCount;

            userExams.filter(exam => examData.id === exam.id).length > 0 ? examData.passed = true : examData.passed = false;

            setCurrentExam(examData);

        } catch (error) {
            enqueueSnackbar('Something went wrong loading the exam.');
        }

        setIsItemLoading(false);
    };

    const handleItemClicked = async (id, type) => {

        setSelectedItem({
            id,
            type
        });

        switch(type){
            case 'exam':
                setCurrentLesson(null);
                await loadExam(id);
                break;
            case 'lesson':
                setCurrentExam(null);
                await loadLesson(id);
                break;
        }
    };

    const handleVideoProgress = async (progress) => {
        if(progress.played >= 0.9 && !isSyncing){
            if(userLessons.filter((lesson) => lesson.id === currentLesson.id).length === 0){
                setIsSyncing(true);

                const data = {
                    id: currentLesson.id
                };

                const response = await axios.post(`${API_URL}/course/lesson`, data)

                setUserLessons(response.data);

                setIsSyncing(false);
            }
        }
    };

    return <>
        {
            isLoading ?
                <div className={classes.loading}>
                    <div className={classes.progress}>
                        <CircularProgress/>
                    </div>
                </div>:
                <div className={classes.root}>
                    {
                        isItemLoading ?
                            <div className={classes.loading} style={{marginRight: '420px'}}>
                                <div className={classes.progress}>
                                    <CircularProgress/>
                                </div>
                            </div> :
                            <>
                                {
                                    currentLesson ?
                                        <Paper style={{
                                            marginRight: '420px',
                                            marginTop: 20,
                                            marginBottom: 20,
                                            marginLeft: 20,
                                            width: '100%'
                                        }}>
                                            <div className={classes.lessonWrapper}>
                                                <div className={classes.wrapper}>
                                                    <ReactPlayer
                                                        url={currentLesson.url}
                                                        className={classes.player}
                                                        width="100%"
                                                        height="100%"
                                                        controls={true}
                                                        onProgress={handleVideoProgress}/>
                                                </div>
                                                <Typography variant="h5"
                                                            style={{marginTop: 20}}>{currentLesson.title}</Typography>
                                                {
                                                    currentLesson.description ?
                                                        <>
                                                            <Typography variant="h6" style={{marginTop: 20, marginBottom: 10}}>In this lesson</Typography>
                                                            <Typography variant="body1">{currentLesson.description}</Typography>
                                                        </> :
                                                        null
                                                }
                                            </div>
                                        </Paper>:
                                        null
                                }
                                {
                                    currentExam ?
                                        <Paper style={{
                                            marginRight: '420px',
                                            marginTop: 20,
                                            marginBottom: 20,
                                            marginLeft: 20,
                                            width: '100%',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}>
                                            {
                                                currentExam.available ?
                                                    currentExam.passed ?
                                                        <div>
                                                            <Typography
                                                                variant="h5" style={{ textAlign: 'center'}}>Exam: {currentExam.title}</Typography>
                                                            <Typography variant="body1" style={{marginTop: 20, textAlign: 'center'}}>
                                                                Congratulations! You've passed this exam.
                                                            </Typography>
                                                        </div> :
                                                        <div>
                                                            <Typography
                                                                variant="h5" style={{ textAlign: 'center'}}>Exam: {currentExam.title}</Typography>
                                                            <Typography variant="body1" style={{marginTop: 20, textAlign: 'center'}}>
                                                                Test your knowledge on the topics of this course. Answer the exam to get your grade.
                                                            </Typography>
                                                            <div className={ classes.buttonWrapper }>
                                                                <Button variant="contained" color="primary"
                                                                        style={{textAlign: 'center'}}
                                                                        onClick={() => history.push(`/student/exams/${currentExam.id}`)}>Answer</Button>
                                                            </div>
                                                        </div>:
                                                    <div>
                                                        <Typography
                                                            variant="h5" style={{ textAlign: 'center'}}>Exam: {currentExam.title}</Typography>
                                                        <Typography variant="body1" style={{marginTop: 20, textAlign: 'center'}}>
                                                            You must complete all of this modules lessons to take this exam.
                                                        </Typography>
                                                    </div>
                                            }
                                        </Paper>:
                                        null
                                }
                            </>
                    }
                    <Paper className={classes.sideBar}>
                        <div className={classes.appBarSpacer} />
                        <List>
                            <ModuleList
                                modules={ modules }
                                openedModule={ openedModule }
                                userLessons={ userLessons }
                                userExams={ userExams }
                                selectedItem={ selectedItem }
                                onOpenedModuleChange={ (id) => setOpenedModule(id) }
                                onItemClicked={handleItemClicked}
                            />
                        </List>
                    </Paper>
                </div>
        }
    </>;
};

export default withSnackbar(Home);
