import React from 'react';
import ModuleListItem from "../ModuleListItem/ModuleListItem";
import LessonListItem from "../LessonListItem/LessonListItem";
import ExamListItem from "../ExamListItem/ExamListItem";

const ModuleList = ({ modules, userLessons, userExams, selectedItem, openedModule, onOpenedModuleChange, onItemClicked }) => {

    return modules.map((module) => (
        <ModuleListItem key={`module_${module.id}`} module={module} userLessons={userLessons} userExams={userExams} isOpen={openedModule === module.id} onOpenedChange={() => onOpenedModuleChange(openedModule === module.id ? null : module.id)}>
            {
                module.lessons.map(lesson => (
                    <LessonListItem
                        key={`lesson_${lesson.id}`}
                        lesson={lesson}
                        userLessons={userLessons}
                        onLessonClicked={(id) => onItemClicked(id, 'lesson')}
                        selected={ selectedItem.id === lesson.id && selectedItem.type === 'lesson' }
                    />
                ))
            }
            {
                module.exams.map(exam => (
                    <ExamListItem
                        key={`exam_${exam.id}`}
                        exam={exam}
                        userExams={userExams}
                        onExamClicked={(id) => onItemClicked(id, 'exam')}
                        selected={ selectedItem.id === exam.id && selectedItem.type === 'exam' }
                    />
                ))
            }
        </ModuleListItem>
    ));
};

export default ModuleList;
