import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import {NavLink} from "react-router-dom";
import axios from "axios";
import {API_URL} from "../../../../config";
import {withSnackbar} from "notistack";
import LinearProgress from "@material-ui/core/LinearProgress";
import WarningIcon from "@material-ui/icons/Warning";
import ExamsEditForm from "../../../../components/Forms/ExamsEditForm";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    padding: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    title: {
        flex: '1 1 100%',
    },
    errorWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: '100%'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 16
    },
    progress: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    backButton: {
        marginRight: 8
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    formControl: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: '100%',
    },
    appBarSpacer: theme.mixins.toolbar,
    container: {
        display: 'flex',
        flex: 1,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
}));

const ExamsEdit = ({ match, enqueueSnackbar }) => {

    const classes = useStyles();

    // ID del registro
    const id = match.params.id;

    // State de módulos
    const [modules, setModules] = useState([]);

    /* *** State de formulario *** */

    const [formFields, setFormFields] = useState({
        title: '',
        module_id: '',
        questions: []
    });

    /* *** State de formulario *** */

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [error, setError] = useState('');

    useEffect(() => {

        const init = async () => {
            await loadModules();
            await loadExam();
        };

        init();

    }, []);

    // Función que carga los módulos
    const loadModules = async () => {

        // Mostrar indicador de carga
        setIsLoading(true);

        try {

            // Hacer request
            const response = await axios.get(
                `${API_URL}/modules`,
                {
                    params: {
                        sort: 'name',
                        direction: 'asc'
                    }
                }
            );

            // Actualizar state
            setModules(response.data);

        } catch(error) {

            enqueueSnackbar('Something went wrong loading the modules.');

        }

        // Esconder indicador de carga
        setIsLoading(false);
    };

    // Función que carga el registro
    const loadExam = async () => {

        setIsLoading(true);

        try {

            const response = await axios.get(`${API_URL}/exams/${id}`);
            const examData = response.data.exam;

            setFormFields(examData);

        } catch(error){

            setError('Something went wrong loading the record.');

            enqueueSnackbar('Something went wrong loading the record.');

        }

        // Esconder indicador de carga
        setIsLoading(false);

    };

    return (
        <>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <div className={classes.root}>
                    {
                        isLoading ?
                            <div className={ classes.progress }>
                                <CircularProgress />
                            </div> :
                            error !== '' ?
                                <div className={ classes.errorWrapper }>
                                    <WarningIcon color="primary" style={ { marginRight: 8 } }/>
                                    <Typography>{ error }</Typography>
                                </div> :
                                <Paper className={classes.paper}>
                                    <LinearProgress style={ { visibility: isSubmitting ? 'visible' : 'hidden' } } />
                                    <Toolbar className={classes.padding}>
                                        <IconButton className={ classes.backButton } component={ NavLink } to="/exams">
                                            <ArrowBackIcon color="secondary" />
                                        </IconButton>
                                        <Typography className={classes.title} variant="h6" id="title">Edit Exam</Typography>
                                    </Toolbar>
                                    <div>
                                        <div className={classes.padding}>
                                            <ExamsEditForm
                                                initialValues={ formFields }
                                                modules={modules}
                                                submitting={isSubmitting}
                                                onSuccess={ (message) => enqueueSnackbar(message) }
                                                onError={ (message) => enqueueSnackbar(message) }
                                                onLoadingChange={ (loading) => setIsSubmitting(loading)}
                                            />
                                        </div>
                                    </div>
                                </Paper>
                    }
                </div>
            </Container>
        </>
    )
};

export default withSnackbar(ExamsEdit);
