import React, {useRef, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import * as moment from 'moment';
import axios from 'axios';
import {API_URL} from "../../../../config";
import Dialog from "../../../../components/Dialog/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import Table from "../../../../components/Table/Table";
import {generateApiUrl, getDeactivatedMessage} from "../../../../helpers/helpers";
import {withSnackbar} from "notistack";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginBottom: 30
    },
    toolbar: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    action: {
        marginRight: 8
    },
    progressBar: {
        width: '100%'
    },
    appBarSpacer: theme.mixins.toolbar,
    container: {
        display: 'flex',
        flex: 1,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
}));

const ModulesIndex = ({ history, enqueueSnackbar }) => {

    const classes = useStyles();

    // State de tabla
    const [selected, setSelected] = useState([]);

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(true);

    // State de diálogo
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);

    let [columnObject] = useState([
        { title: 'ID', field: 'id', filtering: false},
        {
            title: 'Name',
            field: 'name',
            filtering: false,
            render: rowData =>
                (<div style={{minWidth: '200px'}}>  {rowData.name}  </div>)
        },
        {
            title: 'Description',
            field: 'description',
            filtering: false,
            render: rowData =>
                (<div style={{minWidth: '400px'}}>  {rowData.description}  </div>)
        },
        { title: 'Order', field: 'order', filtering: false },
        {
            title: 'Created',
            field: 'created_at',
            filtering: false,
            render: rowData => {
                return moment(rowData.created_at).format('D MMM YYYY');
            }
        },
        {
            title: 'Status',
            field: 'is_active',
            type: 'boolean',
        },
    ]);

    const tableRef = useRef(null);

    // Función que se ejecuta al enviar pulsar el botón de desactivar
    const handleDeactivate = async () => {

        // Esconder el diálogo
        setIsDialogOpen(false);

        // Mostrar el indicador de carga
        setIsLoading(true);

        try {

            // Total de seleccionados
            let count = selected.length;

            // Contadores para mensaje
            let deactivatedCount = 0;
            let activatedCount = 0;

            // Por cada categoría seleccionada
            for(let i = 0; i < count; i++){

                // Cambiar estado
                await axios.delete(`${API_URL}/modules/${selected[i].id}`);

                // Actualizar contador
                if(selected[i].is_active)
                    deactivatedCount += 1;
                else
                    activatedCount += 1;
            }

            // Conseguir el mensaje
            const resultMessage = getDeactivatedMessage(deactivatedCount, activatedCount);

            // Mostrar mensaje
            enqueueSnackbar(resultMessage);

            // Vaciar selección
            setSelected([]);

            // Cargar tabla
            tableRef.current.onQueryChange();

        } catch (error) {

            // Mostrar mensaje
            enqueueSnackbar('Something went wrong activating or deactivating the records.');
        }

        // Esconder indicador de carga
        setIsLoading(false);

    };

    // Función que define los filtros y carga los registros
    const loadTableData = (query) => {

        return new Promise(async (resolve, reject) => {

            // Mostrar indicador de carga
            setIsLoading(true);

            // Generar el url
            const url = generateApiUrl(`${API_URL}/modules`, query);

            try {

                // Realizar request
                const response = await axios.get(url);

                resolve({data: response.data.data, page: response.data.current_page - 1, totalCount: response.data.total});

            } catch(error){
                reject(new Error('Something went wrong loading the records.'))
            }

            // Esconder indicador de carga
            setIsLoading(false);

        }).catch((error) => {

            // Mostrar mensaje
            enqueueSnackbar('Something went wrong loading the records.');

        });
    };

    return (
        <>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <div className={classes.root}>
                    <Table
                        title="Modules"
                        tableRef={tableRef}
                        loading={ isLoading }
                        columns={ columnObject }
                        loadTableData={ (query) => loadTableData(query) }
                        selected={ selected }
                        onSelect={ (rows) => setSelected(rows) }
                        onCreate={ () => history.push('/modules/create') }
                        onEdit={ (id) => history.push(`/modules/${id}/edit`) }
                        onDestroy={ () => setIsDialogOpen(true) }
                    />
                    <Dialog
                        open={isDialogOpen}
                        onClose={() => setIsDialogOpen(false)}
                        onAccept={ handleDeactivate }
                        title="Deactivate or activate records"
                    >
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to deactivate or activate the selected records?
                        </DialogContentText>
                    </Dialog>
                </div>
            </Container>
        </>
    );

};

export default withSnackbar(ModulesIndex);
