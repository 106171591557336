import React from 'react';
import {tableIcons} from "../../config";
import {Add, AssistantPhoto, Edit} from "@material-ui/icons";
import MaterialTable from "material-table";
import * as moment from 'moment';

const Table = (
    {
        tableRef,
        title,
        loading,
        columns,
        loadTableData,
        selected,
        onSelect,
        onCreate,
        onEdit,
        onDestroy,
        addTooltip = 'Add record',
        editTooltip = 'Edit record',
        destroyTooltip = 'Deactivate records',
        canCreate = true,
        canEdit = true,
        canDelete = true
    }) => {

    return <MaterialTable
        title={ title }
        tableRef={tableRef}
        icons={ tableIcons }
        isLoading={ loading }
        columns={
            columns
        }
        data={ loadTableData }
        actions={[
            canCreate ?
                {
                    icon: Add,
                    tooltip: addTooltip,
                    isFreeAction: true,
                    onClick: (event) => onCreate()
                } :
                null,
            canEdit ?
                {
                    icon: Edit,
                    tooltip: editTooltip,
                    onClick: (event, rowData) => onEdit(rowData[0].id),
                    disabled: (selected.length > 1)
                } :
                null,
            canDelete ?
                {
                    icon: AssistantPhoto,
                    tooltip: destroyTooltip,
                    onClick: (event, rowData) => onDestroy(),
                } :
                null
        ]}
        options={{
            selection: true,
            filtering: true,
            exportButton: true,
            exportFileName: `${title}_Safe_Drive_${ moment().format('YYYY_MM_DD')}`,
            emptyRowsWhenPaging: false,
            pageSizeOptions: [10, 25, 50],
            pageSize: 10,
        }}
        onSelectionChange={onSelect}
    />
};

export default Table;
