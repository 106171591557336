import React from 'react';
import {Form} from "react-final-form";
import {Radios, Select, Switches, TextField} from "mui-rff";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import validate from "validate.js";
import axios from "axios";
import {API_URL, APP_URL} from "../../config";
import CircularProgress from "@material-ui/core/CircularProgress";
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays';
import Typography from "@material-ui/core/Typography";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from "@material-ui/core/Grid";
import Message from "../Message/Message";
import {isEmpty} from "lodash";

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 16
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    formControl: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: '100%',
    },
    flexCenter: {
        display: 'flex',
        alignItems:'center',
        justifyContent:'center',
        flexDirection:'row'
    },
    questionWrapper: {
        paddingBottom: 20,
        paddingLeft: 20,
        paddingRight: 20,
        borderStyle: 'dotted',
        borderWidth: 1,
        borderColor: '#e0e0e0',
        borderRadius: 3,
        marginBottom: theme.spacing(2)
    },
    subtitle: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    image: {
        display: 'block',
        margin: '16px auto 0 auto',
        maxWidth: '100%'
    },
    imageWrapper: {
        backgroundColor: '#ededed',
        borderRadius: 4
    }
}));

validate.validators.array = (arrayItems, itemConstraints) => {

    const arrayItemErrors = arrayItems.reduce((errors, item, index) => {
        const error = validate(item, itemConstraints, {fullMessages: false})
        if (error) errors.push({...error, index})
        return errors
    }, []);

    return isEmpty(arrayItemErrors) ? null : arrayItemErrors
};

const ExamsAnswerForm = ({ initialValues, examData, onSuccess, onError, onLoadingChange, submitting }) => {

    const classes = useStyles();

    // Reglas de validación de formulario
    const constraints = {
        questions: {
            array: {
                answer: {
                    presence: {
                        allowEmpty: false
                    }
                }
            }
        }
    };

    // Función que se ejecuta al enviar formulario
    async function onSubmit(values, form) {

        // Mostrar indicador de carga
        onLoadingChange(true);

        try {

            // Datos a enviar
            const data = {
                exam_id: values.exam,
                questions: values.questions,
            };

            // Enviar datos a API
            const response = await axios.post(
                `${API_URL}/course/exam`,
                data
            );

            onSuccess(response.data);

            // Esconder indicador de carga
            onLoadingChange(false);

        } catch (error) {

            // Esconder indicador de carga
            onLoadingChange(false);

            // Dependiendo del error, mostrar mensajes
            switch(error.response.status){
                case 400:
                    // Mostrar mensaje
                    onError("There's one or more validation errors.");
                    return handleServerError(error.response.data.errors);
                case 422:
                    // Mostrar mensaje
                    onError("There's one or more validation errors.");
                    return handleServerError(error.response.data.errors);
                default:
                    // Mostrar mensaje
                    onError('Something went wrong saving.');
                    break;
            }

        }

    }

    // Función que valida el formulario
    async function validateForm(values) {

        // Validar campos
        let valid = validate({
            questions: values.questions
        }, constraints, {fullMessages: false});

        // Si no hay error
        if(!valid) {
            return;
        } else {
            return formatValidation(valid);
        }
    }

    const formatValidation = (valid) => {

        if(valid !== 'Valid'){

            // Modificar objeto para mostrar errores en formulario
            Object.keys(valid).forEach((key, index) => {
                if(key === 'questions'){
                    let newValues = [];

                    for(let i = 0; i < valid[key].length; i++){
                        newValues[valid[key][i].index] = formatValidation(valid[key][i]);
                    }

                    valid[key] = newValues;
                } else if(key !== 'index'){
                    valid[key] = valid[key][0];
                }
            });

        }

        return valid;

    };

    // Función que manipula objeto para mostrar errores
    const handleServerError = (error) => {

        Object.keys(error).forEach((key, index) => {
            error[key] = error[key][0];
        });

        return error;
    };

    const getRadiosData = (options) => {
        return options.map(option => ({label: option.value, value: '' + option.id}));
    };

    const renderQuestions = (fields, push, values) => {

        return fields.map((name, index) => (
            <div key={name} className={ classes.questionWrapper } >
                {
                    examData.questions[index].image ?
                        <div className={classes.imageWrapper}>
                            <img className={classes.image} src={`${APP_URL}/storage/questions/${examData.questions[index].image}`} width={500}/>
                        </div> :
                        null
                }
                <Radios
                    label={examData.questions[index].title}
                    name={`${name}.answer`}
                    required={true}
                    data={getRadiosData(examData.questions[index].options)}
                />
            </div>
        ));
    };

    return (
        <Form
            onSubmit={onSubmit}
            mutators={{
                ...arrayMutators
            }}
            initialValues={initialValues}
            validate={validateForm}
            render={({
                         handleSubmit,
                         values,
                         submitError,
                         form,
                         form: {
                             mutators: { push, pop }
                         }
                     }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <FieldArray name="questions">
                        {({ fields }) => renderQuestions(fields, push, values)}
                    </FieldArray>
                    <div className={ classes.buttonWrapper }>
                        <Button
                            color="secondary"
                            type="submit"
                            disabled={submitting}
                            startIcon={ submitting ? <CircularProgress size={18}/> : null }
                        >
                            Save Answers
                        </Button>
                    </div>
                </form>
            )}
        />
    );
};

export default ExamsAnswerForm;
