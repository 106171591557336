import React from 'react';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import Collapse from "@material-ui/core/Collapse/Collapse";
import List from "@material-ui/core/List";
import FolderIcon from '@material-ui/icons/Folder';

const ModuleListItem = ({ module, userLessons, userExams, isOpen, onOpenedChange, children }) => {

    const getCompletedCount = (lessons, exams, moduleId) => {
        return lessons.filter(lesson => moduleId === lesson.module_id).length + exams.filter(exam => moduleId === exam.module_id).length;
    };

    const getTotalCount = (lessons, exams) => {
        return lessons.length + exams.length
    };

    return <>
        <ListItem button onClick={() => onOpenedChange(module.id)}>
            <ListItemIcon>
                <FolderIcon color="primary" />
            </ListItemIcon>
            <ListItemText
                primary={ module.name }
                secondary={ `${getCompletedCount(userLessons, userExams, module.id)}/${getTotalCount(module.lessons, module.exams)}` } />
            {isOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                { children }
            </List>
        </Collapse>
    </>
};

export default ModuleListItem;
