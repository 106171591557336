import React from 'react';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import AssignmentIcon from '@material-ui/icons/Assignment';
import PanoramaFishEyeIcon from '@material-ui/icons/PanoramaFishEye';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {makeStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction";

const useStyles = makeStyles(theme => ({
    nested: {
        paddingLeft: theme.spacing(4),
    }
}));

const ExamListItem = ({ exam, userExams, onExamClicked, selected }) => {

    const classes = useStyles();

    return <ListItem button className={classes.nested} onClick={() => onExamClicked(exam.id)} selected={selected}>
        <ListItemIcon>
            <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary={ exam.title } />
        <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="delete">
                {
                    userExams.filter(userExam => exam.id === userExam.id).length > 0 ?
                        <CheckCircleIcon color="primary"/> :
                        <PanoramaFishEyeIcon color="primary"/>
                }
            </IconButton>
        </ListItemSecondaryAction>
    </ListItem>
};

export default ExamListItem;
