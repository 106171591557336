import React, {useEffect, useState} from 'react';
import './App.css';
import {connect, useSelector} from "react-redux";
import axios from 'axios';
import {createMuiTheme, ThemeProvider} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {API_URL} from "./config";
import {signIn} from "./store/actions/auth";
import Dashboard from "./components/Dashboard/Dashboard";
import SignIn from "./containers/Auth/SignIn/SignIn";
import Loading from "./containers/Loading/Loading";
import {SnackbarProvider} from "notistack";

function App({ signIn }) {

  const authState = useSelector((state) => state.authReducer);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    const checkUser = async () => {
      setIsLoading(true);

      try {
        const response = await axios.get(`${API_URL}/user`);

        signIn(response.data.user.id, response.data.user.name, response.data.role.id);

      } catch (error) {

      }

      setIsLoading(false);
    };

    checkUser();
  }, []);

  // Tema de Material UI
  const theme = createMuiTheme({
    palette: {
      primary: { main: '#f89e12' },
      secondary: { main: '#0c0c0c' }
    },
    props: {
      MuiTextField: {
        margin: 'normal',
      },
      MuiFormControl: {
        margin: 'normal',
      },
    }
  });

  return <ThemeProvider theme={ theme }>
    <SnackbarProvider maxSnack={3}>
      <MuiPickersUtilsProvider utils={MomentUtils} locale={'es'}>
        <BrowserRouter>
          {
            isLoading ?
                <Loading />:
                <Switch>
                  {
                    authState.isLoggedIn ?
                        <Route path="/" component={ Dashboard } />:
                        null
                  }
                  <Route path="/login" exact component={ SignIn } />
                  <Redirect from="/" to="/login" />
                </Switch>
          }
        </BrowserRouter>
      </MuiPickersUtilsProvider>
    </SnackbarProvider>
  </ThemeProvider>

}

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (userId, name, roleId) =>
        dispatch(signIn(userId, name, roleId))
  };
};

export default connect(null, mapDispatchToProps)(App);
