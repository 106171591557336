import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from "@material-ui/core/IconButton";
import {NavLink} from "react-router-dom";
import {withSnackbar} from "notistack";
import LinearProgress from "@material-ui/core/LinearProgress";
import ModulesCreateForm from "../../../../components/Forms/ModulesCreateForm";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    padding: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    title: {
        flex: '1 1 100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 16
    },
    progress: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    backButton: {
        marginRight: 8
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    formControl: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: '100%',
    },
    appBarSpacer: theme.mixins.toolbar,
    container: {
        display: 'flex',
        flex: 1,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
}));

const ModulesCreate = ({ enqueueSnackbar }) => {

    const classes = useStyles();

    /* *** State de formulario *** */

    const formFields = {
        name: '',
        description: '',
        order: ''
    };

    /* *** State de formulario *** */

    // State de indicador de carga
    const [isSubmitting, setIsSubmitting] = useState(false);

    return (
        <>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <div className={classes.root}>
                    <Paper className={classes.paper}>
                        <LinearProgress style={ { visibility: isSubmitting ? 'visible' : 'hidden' } } />
                        <Toolbar className={classes.padding}>
                            <IconButton className={ classes.backButton } component={ NavLink } to="/modules">
                                <ArrowBackIcon color="secondary" />
                            </IconButton>
                            <Typography className={classes.title} variant="h6" id="title">New Module</Typography>
                        </Toolbar>
                        <div>
                            <div className={classes.padding}>
                                <ModulesCreateForm
                                    initialValues={ formFields }
                                    submitting={isSubmitting}
                                    onSuccess={ (message) => enqueueSnackbar(message) }
                                    onError={ (message) => enqueueSnackbar(message) }
                                    onLoadingChange={ (loading) => setIsSubmitting(loading)}
                                />
                            </div>
                        </div>
                    </Paper>
                </div>
            </Container>
        </>
    )
};

export default withSnackbar(ModulesCreate);
