import React from 'react';
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";

const Snack = ({ open, message, handleClose }) => {

    return <Snackbar
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={ handleClose }
        message={ message }
        action={
            <React.Fragment>
                <Button color="primary" size="small" onClick={handleClose}>
                    CERRAR
                </Button>
            </React.Fragment>
        }
    />
};

export default Snack;
