
export const generateApiUrl = (baseUrl, query) => {

    // Generar URL base y agregar opciones de paginación
    let url = baseUrl + '?';
    url += 'per_page=' + query.pageSize;
    url += '&page=' + (query.page + 1);

    // Si se seleccionaron opciones de orden, agregar a URL
    if(query.orderBy && query.orderDirection){
        url += '&sort=' + query.orderBy.field;
        url += '&direction=' + query.orderDirection;
    }

    // Si se seleccionaron filtros, agregar a URL
    if(query.filters){
        for(let i = 0; i < query.filters.length; i++){
            if(query.filters[i].column.field === 'is_active')
                url += `&${query.filters[i].column.field}=${query.filters[i].value === 'checked' ? 1 : 0}`;
            else
                url += `&${query.filters[i].column.field}=${query.filters[i].value}`;
        }
    }

    // Si se llenó el campo de búsqueda, agregar a filtros
    if(query.search)
        url += '&search=' + query.search;

    return url;
};

export const getDeactivatedMessage = (deactivatedCount, activatedCount) => {

    if(deactivatedCount > 1 && activatedCount > 1){
        return`${deactivatedCount} records deactivated and ${activatedCount} records activated.`;
    } else if(deactivatedCount === 1 && activatedCount === 1){
        return `1 record deactivated and 1 record activated.`;
    } else if(deactivatedCount > 1 && activatedCount === 0){
        return `${deactivatedCount} records deactivated.`;
    } else if(deactivatedCount === 1 && activatedCount === 0){
        return `1 record deactivated.`;
    } else if(deactivatedCount === 0 && activatedCount > 1){
        return `${activatedCount} records activated.`;
    } else if(deactivatedCount === 0 && activatedCount === 1){
        return `1 record activated.`;
    } else if(deactivatedCount === 1 && activatedCount > 1){
        return `1 record deactivated and ${activatedCount} records activated.`;
    } else {
        return `${deactivatedCount} records deactivated and 1 record activated.`;
    }

};
