import React from 'react';
import {Form} from "react-final-form";
import {TextField} from "mui-rff";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import validate from "validate.js";
import axios from "axios";
import {API_URL} from "../../config";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 16
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    formControl: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: '100%',
    },
}));

const ModulesCreateForm = ({ initialValues, onSuccess, onError, onLoadingChange, submitting }) => {

    const classes = useStyles();

    // Reglas de validación de formulario
    const constraints = {
        name: {
            presence: {
                allowEmpty: false
            }
        },
        description: {
            presence: {
                allowEmpty: false
            }
        },
        order: {
            presence: {
                allowEmpty: false
            },
            numericality: {
                onlyInteger: true,
                greaterThanOrEqualTo: 0,
                message: "The value must be equal or greater than 0"
            }
        }
    };

    // Función que se ejecuta al enviar formulario
    async function onSubmit(values, form) {

        // Mostrar indicador de carga
        onLoadingChange(true);

        try {

            // Datos a enviar
            const data = {
                name: values.name,
                description: values.description,
                order: values.order,
            };

            // Enviar datos a API
            await axios.post(
                `${API_URL}/modules`,
                data
            );

            setTimeout(form.restart);

            // Enviar mensaje de éxito a componente padre
            onSuccess('Module created.');

            // Esconder indicador de carga
            onLoadingChange(false);

        } catch (error) {

            // Esconder indicador de carga
            onLoadingChange(false);

            // Dependiendo del error, mostrar mensajes
            switch(error.response.status){
                case 400:
                    // Mostrar mensaje
                    onError("There's one or more validation errors.");
                    return handleServerError(error.response.data.errors);
                case 422:
                    // Mostrar mensaje
                    onError("There's one or more validation errors.");
                    return handleServerError(error.response.data.errors);
                default:
                    // Mostrar mensaje
                    onError('Something went wrong creating.');
                    break;
            }

        }

    }

    // Función que valida el formulario
    async function validateForm(values) {

        // Validar campos
        let valid = validate({
            name: values.name,
            description: values.description,
            order: values.order
        }, constraints, {fullMessages: false});

        // Si no hay error
        if(!valid)
            return;
        else {

            // Modificar objeto para mostrar errores en formulario
            Object.keys(valid).forEach((key, index) => {
                valid[key] = valid[key][0];
            });

            return valid;
        }
    }

    // Función que manipula objeto para mostrar errores
    const handleServerError = (error) => {

        Object.keys(error).forEach((key, index) => {
            error[key] = error[key][0];
        });

        return error;
    };

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            validate={validateForm}
            render={({ handleSubmit, values, submitError, form }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={3}>
                        <Grid item sm={9} xs={12}>
                            <TextField label="Name" name="name" />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <TextField label="Order" name="order" type="number" />
                        </Grid>
                    </Grid>
                    <TextField label="Description" name="description" multiline rows={3} />
                    <div className={ classes.buttonWrapper }>
                        <Button
                            color="secondary"
                            type="submit"
                            disabled={submitting}
                            startIcon={ submitting ? <CircularProgress size={18}/> : null }
                        >
                            Create
                        </Button>
                    </div>
                </form>
            )}
        />
    );
};

export default ModulesCreateForm;
