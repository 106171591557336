import React from 'react';
import Typography from "@material-ui/core/Typography";
import SmsFailedIcon from '@material-ui/icons/SmsFailed';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    message: {
        padding: 20,
        marginBottom: 20,
        borderTopStyle: 'solid',
        borderTopColor: theme.palette.primary.main,
        borderTopWidth: 2,
        backgroundColor: '#FAFAFA',
        borderBottomLeftRadius: 2,
        borderBottomRightRadius: 2,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'row'
    }
}));

const Message = ({ message, style }) => {

    const classes = useStyles();

    return <div className={ classes.message } style={style}>
        <SmsFailedIcon color="primary" style={{ marginRight: 10 }} />
        <Typography>{ message }</Typography>
    </div>
};

export default Message;
