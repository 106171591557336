import {SIGNIN_SUCCESS, SIGNOUT_SUCCESS} from "../actionTypes";

// State inicial del reducer
const authState = {
    isLoggedIn: false,
    userId: null,
    name: null,
    roleId: null
};

// State de autenticación
const authReducer = (state = authState, action) => {
    switch(action.type){
        case SIGNIN_SUCCESS:
            return { isLoggedIn: true, ...action.payload };
        case SIGNOUT_SUCCESS:
            return { isLoggedIn: false, userId: null, name: null, roleId: null };
        default:
            return state;
    }
};

export default authReducer;
